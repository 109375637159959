<template>
  <div class="user-management" ref="userGroupManagement">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="84px"
      >
        <el-form-item label="用户组名称：">
          <el-input
            v-model.trim="form.name"
            placeholder="用户组名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="用户组类型：">
          <el-select clearable v-model.trim="form.userGourpType">
            <el-option
              v-for="item in userGroupTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSubmit"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="text-right">
          <el-button
            type="primary"
            size="small"
            @click="onAddGroup"
            v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号" width="100"></el-table-column>
      <el-table-column prop="name" label="用户组名称"></el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          {{ userGroupObj[Number(scope.row.type)] }}
        </template></el-table-column
      >
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onGroupUpdate(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="
              $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
              scope.row.status === 0
            "
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === 0 ? 'danger' : 'primary'"
            @click="onDisableUser(scope.row)"
            v-if="
              scope.row.status === 0
                ? $store.state.menu.nowMenuList.indexOf('禁用') >= 0
                : $store.state.menu.nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === 0 ? '禁用' : '启用' }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <div>
        <div class="body">
          <el-form
            :model="addForm"
            :rules="rules"
            ref="addForm"
            label-width="110px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="用户组名称：" prop="userGroupName">
              <el-input v-model.trim="addForm.userGroupName"></el-input>
            </el-form-item>
            <el-form-item label="用户组类型：" prop="userGroupType">
              <el-select
                v-model="addForm.userGroupType"
                placeholder="用户组类型"
              >
                <el-option
                  v-for="item in availableUserGroupTypeList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消息文本：" prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                maxlength="50"
                show-word-limit
                placeholder="请输入内容"
                v-model.trim="addForm.remark"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkName, formatDict } from '@/common/utils/index.js'
import {
  queryUserGroup,
  addUserGroup,
  updateUserGroup,
  updateUserGroupStatus,
  queryUserGroupTypeList,
  queryDictsByCodes
} from '@/api/lib/api.js'

export default {
  name: 'userGroupManagement',
  components: {},
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      userGroupTypeList: [],
      userGroupObj: null,
      availableUserGroupTypeList: [],
      form: {
        name: '',
        userGourpType: '',
        currentPage: 1,
        pageSize: 10
      },
      addForm: {
        id: '',
        status: null,
        userGroupName: '',
        userGroupType: '',
        remark: ''
      },
      rules: {
        userGroupName: [
          { required: true, message: '请输入用户组名称', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        userGroupType: [
          { required: true, message: '请选择用户组类型', trigger: 'change' }
        ]
      },
      total: 0,
      dialogVisible: false,
      title: '',
      searchLoading: false,
      editLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.userGroupManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10
    },
    // 提交
    onSubmit() {
      this.searchLoading = true
      this.form.currentPage = 1
      this.getUserGroupList()
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
    },
    // 修改用户组信息
    onGroupUpdate(row) {
      this.title = '修改用户组信息'
      this.addForm.userGroupName = row.name
      this.addForm.userGroupType = row.type
      this.addForm.remark = row.remark
      this.addForm.id = row.id
      this.addForm.status = row.status
      this.dialogVisible = true
    },
    // 弹窗关闭时的回调
    onDialogClose() {
      this.$refs.addForm.resetFields()
      this.dialogVisible = false
      this.addForm = {
        id: '',
        status: null,
        userGroupName: '',
        userGroupType: '',
        remark: ''
      }
    },
    //  禁用|启用 用户组
    onDisableUser(row) {
      if (row.status === 0) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [
            h('span', null, '确定禁用用户组 '),
            h('span', { style: 'color: #059370' }, row.name),
            h('span', null, ' ？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            updateUserGroupStatus({ id: row.id, status: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('禁用成功')
                row.status = 1
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消禁用'
            })
          })
      } else {
        updateUserGroupStatus({ id: row.id, status: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.status = 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 新增用户组
    onAddGroup() {
      this.title = '新增用户组'
      this.dialogVisible = true
    },
    // 获取用户组
    getUserGroupList() {
      queryUserGroup(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
        this.searchLoading = false
      })
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogVisible = false
      this.editLoading = false

      this.$refs.addForm.resetFields()
    },
    // 新增/修改用户组
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          let data = {
            name: this.addForm.userGroupName,
            type: this.addForm.userGroupType,
            remark: this.addForm.remark
          }
          if (this.title.indexOf('新增') !== -1) {
            addUserGroup(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.onSubmit()
              } else {
                this.$message.error(res.msg)
              }
              this.editLoading = false
            })
          } else {
            data = {
              ...data,
              id: this.addForm.id,
              status: this.addForm.status
            }
            updateUserGroup(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.getUserGroupList()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
          this.closeDialog()
        } else {
          return false
        }
      })
    },
    //获取字典值（查询时不筛选，新增时筛选出启用状态）此需求暂定
    getDicts() {
      // const arr = []
      queryDictsByCodes({ parentCodes: 'YHZLX' }).then((res) => {
        if (res.code === 1000) {
          this.userGroupTypeList = res.data.YHZLX
          // res.data.YHZLX.forEach(item => {
          //   if (item.status === 0) {
          //     arr.push(item)
          //   }
          // })
          this.availableUserGroupTypeList = res.data.YHZLX
          this.userGroupObj = formatDict(this.userGroupTypeList)
        }
      })
    }
  },

  created() {
    this.getDicts()
    this.getUserGroupList()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.user-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-resourse {
  .user-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
