var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "userGroupManagement", staticClass: "user-management" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "functionButtons",
          staticClass: "function-buttons"
        },
        [_c("el-button", { attrs: { type: "primary", size: "small" } })],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "84px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户组名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "用户组名称", clearable: "" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户组类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.userGourpType,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "userGourpType",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.userGourpType"
                      }
                    },
                    _vm._l(_vm.userGroupTypeList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "text-right" },
                [
                  _vm.$store.state.menu.nowMenuList.indexOf("新增") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onAddGroup }
                        },
                        [_vm._v("新增")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "用户组名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.userGroupObj[Number(scope.row.type)]) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "remark", label: "备注" } }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$store.state.menu.nowMenuList.indexOf("编辑") >= 0 &&
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onGroupUpdate(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    (scope.row.status === 0
                    ? _vm.$store.state.menu.nowMenuList.indexOf("禁用") >= 0
                    : _vm.$store.state.menu.nowMenuList.indexOf("启用") >= 0)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort:
                                scope.row.status === 0 ? "danger" : "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onDisableUser(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status === 0 ? "禁用" : "启用")
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onDialogClose
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rules,
                      "label-width": "110px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "用户组名称：", prop: "userGroupName" }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addForm.userGroupName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "userGroupName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.userGroupName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "用户组类型：", prop: "userGroupType" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "用户组类型" },
                            model: {
                              value: _vm.addForm.userGroupType,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "userGroupType", $$v)
                              },
                              expression: "addForm.userGroupType"
                            }
                          },
                          _vm._l(_vm.availableUserGroupTypeList, function(
                            item
                          ) {
                            return _c("el-option", {
                              key: item.dictCode,
                              attrs: {
                                label: item.dictValue,
                                value: item.dictCode
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "消息文本：", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 2,
                            maxlength: "50",
                            "show-word-limit": "",
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: _vm.addForm.remark,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.editLoading
                  },
                  on: { click: _vm.onSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }